.notification-container {
  text-align: center;
  line-height: 1.6em;
  overflow: hidden;

  p {
    margin-bottom: 2em;
  }

  label {
    height: auto !important;
  }
}

.hide-notification {
  margin-top: 2em;
}
